import React, { useEffect, useState } from "react";
import { ScatterplotFrame } from "./ScatterplotFrame"
import { useSelector } from "react-redux";
import { logsSelector } from "../../services/RoverLogs/RoverLogsSlice";
import { IViewMode } from "../../commons/interfaces/IViewMode";
import { filterResultsSelector } from "../../services/Filter/FilterSlice";

export const RoverScatterplot = ({ viewMode } : IViewMode) => {
  const [width, setWidth] = useState(900);
  const [height, setHeight] = useState(300);

  const streamLogs = useSelector(logsSelector);
  const filteredLogs = useSelector(filterResultsSelector);

  useEffect(() => {
    const updateDimensions = () => {
      setWidth(window.innerWidth - 400);
      setHeight(width*.7);
    };
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
    
  }, []);

  const logsToDisplay = viewMode === 'stream' ? streamLogs : filteredLogs;

  return (
    logsToDisplay && <ScatterplotFrame data={logsToDisplay} width={width} height={height}/>
  )
};

