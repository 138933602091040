type Checkbox = {
    name?: string,
    id: string | number
}

type CheckBoxValues = Checkbox[]

type MarsCheckboxType = {
    selector: string,
    name: string,
    formValues: any,
    setFormValues: (newValue: any) => void,
    label?: string,
    checkboxValues?: CheckBoxValues,
    checkboxGrpCols?: number,
    totalCount?: number,
    required?: boolean,
    searchable?: boolean
}

export default interface IMarsCheckbox{
    fieldValues: MarsCheckboxType
}

export const MarsCheckboxDefaults: IMarsCheckbox = {
    fieldValues: {
        selector: "TEXTFIELD",
        name: "",
        formValues: {},
        setFormValues: () => {},
        label: "",
        checkboxGrpCols: 1,
        checkboxValues: [],
        totalCount: null,
        required: false,
        searchable: false
    }
}