import "./index.css";
import React from "react";
import { Box } from "@mui/material";
import ErrorAlert from "../ErrorAlert";
import { useSelector } from "react-redux";
import { errorSelector } from "../../services/ThunkMiddleware/ThunkMiddlewareSlice";

/**
 * 
 * @returns A react component to display API errors as collapsable 
    stacked messages to the right bottom of the screen
 */
const APIErrorDisplay = () => {
    const errors = useSelector(errorSelector);

    return(
        <Box className="error-wrapper">
            {
                Object.values(errors).map((err: any, i: number) => (
                    <ErrorAlert
                        key={i}
                        variant="filled"
                        severity="error"
                        title="API Error"
                        message={err}
                        collapsable={true}
                        style={{
                            width: "30%", 
                            marginRight: "10px", 
                            marginLeft: "auto", 
                            marginBottom: "10px"
                        }}
                        id={Object.keys(errors)[i]}
                    />
                ))
            }
        </Box>
    )
}

export default APIErrorDisplay;