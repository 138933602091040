import { combineReducers } from "@reduxjs/toolkit";
import themeReducer from "../services/ModeSlice";
import filterReducer from "../services/Filter/FilterSlice";
import searchReducer from "../services/Search/SearchSlice";
import roverAssetReducer from "../services/RoverAsset/RoverAssetSlice";
import roverLogsReducer from "../services/RoverLogs/RoverLogsSlice";
import thunkMiddlewareReducer from "../services/ThunkMiddleware/ThunkMiddlewareSlice";

const appReducer = combineReducers({
    theme: themeReducer,
    filter: filterReducer,
    search: searchReducer,
    roverAsset: roverAssetReducer,
    roverLogs: roverLogsReducer,
    thunkMiddleware: thunkMiddlewareReducer
})

const rootReducer = (state: any, action: any) => {
    return appReducer(state, action)
}

export default rootReducer;