import React from "react";
import { IconButton } from "@mui/material";
import { IMarsIcon } from "./IMarsIcon";

const MarsIcon = (props: IMarsIcon) => {
    return(
        <IconButton onClick={props.clickAction}>
            {props.icon}
        </IconButton>
    )
}

export default MarsIcon;