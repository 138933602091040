import React, { ChangeEvent, MouseEvent, useState } from "react"
import CustomDiv from "../../CustomDiv"
import { Button, Checkbox, FormControlLabel, List, ListItem, Popover, Typography, Link } from "@mui/material"
import TransitionButton from "../../TransitionButton"
import { Search } from "@mui/icons-material"
import CheckboxSearch from "../../CheckboxSearch"
import { default as searchForm } from "../../../data/checkboxSearchForm.json"
import IMarsCheckbox, { MarsCheckboxDefaults } from "./IMarsCheckbox"

const MarsCheckbox = (props: IMarsCheckbox) => {
    let fieldValues = props?.fieldValues

    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const openSearch = (
        event: MouseEvent<HTMLAnchorElement>
    ) => {
        setAnchorEl(event.currentTarget)
        setOpen(true)
    }

    const handleCheckboxChange = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        if(event.target.checked){
            fieldValues.setFormValues({...fieldValues.formValues, 
                [fieldValues.name] : [...fieldValues.formValues[fieldValues.name], event.target.name]
            })
        }else{
            fieldValues.setFormValues({...fieldValues.formValues, 
                [fieldValues.name] : fieldValues.formValues[fieldValues.name].filter(
                    (id: any) => id !== event.target.name
                )
            })
        }
    }

    return(
        <div>
            <CustomDiv>
                <Typography variant="h5" style={{flexGrow: 1}}>{fieldValues.label}</Typography>
                        {fieldValues.searchable ? <TransitionButton icon={<Search />}/> : ''}
                    </CustomDiv>
                    <List style={{columnCount: fieldValues.checkboxGrpCols, paddingBottom: 0}}>
                        {fieldValues.checkboxValues?.map((value: any, index: number) => (
                            <ListItem key={index}>
                                {
                                    fieldValues.formValues &&
                                    fieldValues.formValues[fieldValues.name] && 
                                    Array.isArray(fieldValues.formValues[fieldValues.name]) &&
                                    <FormControlLabel 
                                        control={
                                            <Checkbox 
                                                onChange={handleCheckboxChange} 
                                                name={value.id}/>} 
                                        checked={fieldValues.formValues[fieldValues.name].includes(value.id)}
                                        label={value.id} 
                                    /> 
                                }
                            </ListItem>
                        ))}
                    </List> 
                        <Link onClick={(event) => openSearch(event)} style={{lineHeight: '0.5px'}}>+ More Rovers</Link>
                    <Popover 
                        open={open}  
                        anchorEl={anchorEl}
                        onClose={() => setOpen(false)}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <CheckboxSearch searchForm={searchForm} checkboxValues={fieldValues.formValues} setCheckboxValues={fieldValues.setFormValues} checkboxGrpName={fieldValues.name}/>
                        <CustomDiv horizontalPosition="right" style={{padding: '1vw'}}>
                            <Button variant="outlined" onClick={() => setOpen(false)}>Close</Button>
                        </CustomDiv>
                    </Popover>
                </div>
    )
}

MarsCheckbox.defaultfieldValues = MarsCheckboxDefaults

export default MarsCheckbox;