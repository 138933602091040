import { createSlice } from "@reduxjs/toolkit";
import { HttpRequest } from "../../context/ApiRequest";
import { Endpoints } from "../../commons/constants/Endpoints";
import { RequestType } from "../../commons/constants/RequestType";
import { IRoverAssets, RoverAssetSliceValues } from "./IRoverAssetSlice";

export const initialState : RoverAssetSliceValues = {
    roverAssets: [] as IRoverAssets[]
}

const roverAssetSlice = createSlice({
    name: "roverAsset",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRoverAssets.fulfilled, (state, action) => {
                state.roverAssets = action.payload as IRoverAssets[]
            })
    }
})

export const fetchRoverAssets = HttpRequest(
    `roverAsset${Symbol.keyFor(Endpoints.RoverAssets)}`,
     RequestType.GET, 
     `${Symbol.keyFor(Endpoints.RoverAssets)}`
)

export const roverAssetsSelector = (state: any) => state.roverAsset.roverAssets

export default roverAssetSlice.reducer;
