import { createSlice } from "@reduxjs/toolkit";
import { HttpRequest } from "../../context/ApiRequest";
import { RequestType } from "../../commons/constants/RequestType";
import { Endpoints } from "../../commons/constants/Endpoints";
import { roverIds } from "../../commons/interfaces/RoverIds";

const searchSlice = createSlice({
    name: "search",
    initialState: {
        searchResults: [] as roverIds[]
    },
    reducers: {
        setSearchResults: (state, action) => {
            state.searchResults = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRoversByName.fulfilled, (state, action) => {
                state.searchResults = action.payload as roverIds[]
            })
            .addCase(fetchRoversByYear.fulfilled, (state, action) => {
                state.searchResults = action.payload as roverIds[]
            })  
    }
})

export const fetchRoversByName = HttpRequest(`search${Symbol.keyFor(Endpoints.RoversByName)}`, RequestType.MOCK, Symbol.keyFor(Endpoints.RoversByName))
export const fetchRoversByYear = HttpRequest(`search${Symbol.keyFor(Endpoints.RoversByYear)}`, RequestType.MOCK, Symbol.keyFor(Endpoints.RoversByYear))

export const {setSearchResults} = searchSlice.actions;
export const searchResultsSelector = (state: any) => state.search.searchResults;
export default searchSlice.reducer;