import { roverIds } from "../commons/interfaces/RoverIds"
import {default as roverEntries} from "../data/MockApiResponses/roverEntries.json"

interface IRoverEntries{
    id: string,
    year: number
}

const constructResponse = (filteredEntries: Array<any>) => {
    let result: roverIds[] = []

    return new Promise ((resolve, reject) => {
        var i = 0

        for(let ele of filteredEntries){
            let roverIdObj: roverIds = {id : ele.id} 
    
            result = [...result, roverIdObj]

            i++
        }

        if(i === filteredEntries.length){
            resolve(result)
        }

    })
}

export const searchRoversByName = (text: string) => {
    return new Promise((resolve, reject) => {
        let filteredEntries:any = []

        if(text !== ""){
            filteredEntries = roverEntries.filter((rover: IRoverEntries) =>
                rover.id.toLowerCase().includes(text.toLowerCase()))
        }
        
        constructResponse(filteredEntries).then((response: roverIds[]) => {
            resolve(response)
        })
    })
}

export const searchRoversByYear = (year: number) => {
    return new Promise((resolve, reject) => {
        let filteredEntries = roverEntries.filter((rover: IRoverEntries) => 
        rover.year === year)

        constructResponse(filteredEntries).then((response: roverIds[]) => {
            resolve(response)
        })
    })
}