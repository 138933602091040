import React, { useCallback, useEffect, useState } from "react"
import { Box } from "@mui/material"
import MarsForm from "../MarsForm"
import CustomFields from "../CustomFields"
import { fetchRoversByName, fetchRoversByYear, searchResultsSelector } from "../../services/Search/SearchSlice"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../context/CustomHooks"
import { ICheckboxSearch, ISearchFields } from "./ICheckBoxSearch"

const CheckboxSearch = (props: ICheckboxSearch) => {
    const dispatch = useAppDispatch()

    const searchResultsResponse = useSelector(searchResultsSelector);

    const [searchFields, setSearchFields] = useState<ISearchFields>({
        search: "",
        year: new Date().getFullYear()
    })

    const initSearchResults = useCallback(() => {
        dispatch(fetchRoversByYear(searchFields.year))
    }, [dispatch, searchFields.year])

    useEffect(() => {
        if(searchFields.search === ""){
            initSearchResults()
        }else{
            dispatch(fetchRoversByName(searchFields.search))
        }
    }, [dispatch, initSearchResults, searchFields.search])

    useEffect(() => {
        dispatch(fetchRoversByYear(searchFields.year))
    }, [dispatch, searchFields.year])
    
    return(
        <Box style={{padding: '1.3vw', maxWidth: '400px'}}>
            <MarsForm searchForm={props.searchForm} value={searchFields} setValue={setSearchFields} horizontal={true} fieldGap={'40px'}/>

            <Box style={{padding: '1vw', maxHeight: '130px', overflowY: 'scroll'}}>
                <CustomFields
                    selector="CHECKBOXGROUP"
                    formValues={props.checkboxValues}
                    setFormValues={props.setCheckboxValues}
                    name={props.checkboxGrpName}
                    checkboxValues={searchResultsResponse}
                    checkboxGrpCols={3}
                />
            </Box>
        </Box>
    )
}

export default CheckboxSearch