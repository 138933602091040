import React, { ChangeEvent } from "react"
import { FilledInputProps, InputAdornment, InputProps, OutlinedInputProps, TextField } from "@mui/material"
import IMarsTextField, { MarsTextFieldDefaults } from "./IMarsTextField"
import MarsIcon from "../../MarsIcon"

const MarsTextField = (props: IMarsTextField) => {
    let fieldValues = props?.fieldValues

    const handleTextFieldChange = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        fieldValues.setFormValues({...fieldValues.formValues, [event.target.name] : event.target.value})
    }

    const getInputProps = () => {
        if(fieldValues.iconPosition){
            if(fieldValues.iconPosition === "start"){
                return ({
                    startAdornment: 
                    <InputAdornment position={fieldValues.iconPosition}>
                        {
                            fieldValues.iconPosition && 
                            <MarsIcon 
                                icon={fieldValues.icon} 
                                clickAction={fieldValues.iconClickAction}
                            />
                        }
                    </InputAdornment>
                }) as Partial<InputProps> |
                    Partial<OutlinedInputProps> |
                    Partial<FilledInputProps>
            }else{
                return ({
                    endAdornment: 
                    <InputAdornment position={fieldValues.iconPosition}>
                        {
                            fieldValues.iconPosition && 
                            <MarsIcon 
                                icon={fieldValues.icon} 
                                clickAction={fieldValues.iconClickAction}
                            />
                        }
                    </InputAdornment>, 
                }) as Partial<InputProps> |
                    Partial<OutlinedInputProps> |
                    Partial<FilledInputProps>
            }
        }else{
            return {} as Partial<InputProps> |
                Partial<OutlinedInputProps> |
                Partial<FilledInputProps>
        }
    }

    return(
        <TextField 
            label={fieldValues.isPlaceholder ? "" : fieldValues.label}
            placeholder={fieldValues.isPlaceholder ? fieldValues.label : ""}
            value={fieldValues.formValues?.[fieldValues.name]}
            onChange={handleTextFieldChange}
            name={fieldValues.name}
            variant={fieldValues.variant}
            InputProps={getInputProps()}
            fullWidth
        />
    )
}

MarsTextField.defaultfieldValues = MarsTextFieldDefaults

export default MarsTextField