import React from "react";
import CustomDiv from "../CustomDiv";
import { Typography } from "@mui/material";
import LottieAnimation from "../LottieAnimation";
import { IMessageView } from "./IMessageView";
import {default as animation} from "../../assets/warning.json";

const MessageView = (props: IMessageView) => {
    return(
        <CustomDiv 
            horizontalPosition="center" 
            verticalPosition="center" 
            direction="column"
            style={{height: "inherit"}}
        >

        {(() => {
            switch(props.imgSelector){
                case 'LOTTIE':
                    return(
                        <LottieAnimation 
                            animationEffect={props.imgSrc as object} 
                            height={props.imgHeight} 
                            width={props.imgWidth} 
                            loop={props.loopGif && props.loopGif}
                        /> 
                    ) 
                case "IMAGE":
                    return(
                        <img 
                            src={props.imgSrc as string} 
                            height={props.imgHeight} 
                            width={props.imgWidth}
                            alt="info"
                        />
                    ) 
                default: 
                    return(
                        <LottieAnimation 
                            animationEffect={animation} 
                            height={200} 
                            width={200} 
                            loop={false}
                        />
                    )
            }
        })()}
                    
            {props.title && <Typography variant="h1">{props.title}</Typography>}
            <Typography variant="h5">{props.content}</Typography>
        </CustomDiv>
    )
}

export default MessageView;