import React from "react";
import "./index.css"
import CustomFields from "../CustomFields";
import { Box } from "@mui/material";
import { IMarsForm, MarsFormDefaults } from "./IMarsForm";

const MarsForm = (props: IMarsForm) => {
    return(
        <Box style={{display: props.horizontal? 'flex' : 'block', gap: props.fieldGap}}>
            {props.searchForm && props.searchForm.map((fields: any, index: number) => (
                <Box style={{padding: '1vw 0'}} key={`${fields.name}-${index}`}>
                    <CustomFields
                        key={index}
                        {...fields}
                        {...props.extraProps[index]}
                        formValues={props.value}
                        setFormValues={props.setValue}
                    />
                </Box>
            ))}
        </Box>
       
    )
}

MarsForm.defaultProps = MarsFormDefaults

export default MarsForm;
