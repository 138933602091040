import { colors } from "../../constants/Colors";

export const muiButton = {
    styleOverrides: {
        contained: {
            color: Symbol.keyFor(colors.White),
            padding: '0.6vw 1vw',
            borderRadius: '50px',
            fontSize: 'calc(5px + 0.5vw)'
        }
    }
}