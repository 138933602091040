import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { ColumnParams, IMarsTable } from "./IMarsTable";

const MarsTable = (props: IMarsTable) => {
    return(
        <Table stickyHeader>
            <TableHead>
                <TableRow>
                    {props?.columns?.map((ele: ColumnParams, i:number) => (
                        <TableCell key={i}>{ele.heading}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {props?.tableContent && 
                    props.tableContent.length > 0 && 
                        props.tableContent.map((content:any, row: number) => (
                        <TableRow key={row}>
                            {props.columns?.map((ele: ColumnParams, col: number) => (
                                <TableCell key={col}>{content[ele.column]}</TableCell>
                            ))}
                        </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

export default MarsTable;