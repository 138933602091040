import { IconButton } from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import React from "react";
import { modeSelector, setMode } from "../../services/ModeSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../context/CustomHooks";

const ToggleTheme = () => {
  const dispatch = useAppDispatch();
  const mode = useSelector(modeSelector);

const toggleColorMode = () =>
      dispatch(setMode(mode === 'light' ? 'dark' : 'light'));

  return (
      <IconButton onClick={toggleColorMode} color="inherit">
        {mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>

  );
};

export default ToggleTheme;