// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-wrapper{
    padding: 5vh 2vw;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    max-height: 92vh;
    overflow-y: scroll;
    position: fixed
}

.chips-wrapper{
    padding: 2vw;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

`, "",{"version":3,"sources":["webpack://./src/pages/Home/index.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iDAAiD;IACjD,gBAAgB;IAChB,kBAAkB;IAClB;AACJ;;AAEA;IACI,YAAY;IACZ,iDAAiD;AACrD","sourcesContent":[".filter-wrapper{\n    padding: 5vh 2vw;\n    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;\n    max-height: 92vh;\n    overflow-y: scroll;\n    position: fixed\n}\n\n.chips-wrapper{\n    padding: 2vw;\n    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
