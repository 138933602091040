import React, { useState } from "react";
import { ITransitionButton } from "./ITransitionButton";
import CustomDiv from "../CustomDiv";
import CustomFields from "../CustomFields";
import { Close } from "@mui/icons-material";
import MarsIcon from "../MarsIcon";

const TransitionButton = (props: ITransitionButton) => {
    const [transition, setTransition] = useState(false)

    return(
        transition ? 
            <CustomFields 
                selector="TEXTFIELD"
                iconPosition="end"
                icon={<Close></Close>}
                variant="filled"
                iconClickAction={() => setTransition(false)}
                isPlaceholder={true}
            />
            :
            <CustomDiv horizontalPosition="right">
                <MarsIcon icon={props.icon} clickAction={() => setTransition(true)}/>
            </CustomDiv>         
    )
}

export default TransitionButton;