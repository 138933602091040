import { TextFieldVariants } from "@mui/material"
import { DateView } from "@mui/x-date-pickers"

type MarsDatePickerType = {
    selector: string,
    name: string,
    formValues: any,
    setFormValues: (newValue: any) => void,
    label?: string,
    datePickerViews?: DateView[],
    minDate?: number[],
    maxDate?: number[],
    required?: boolean,
    variant?: TextFieldVariants,
    isPlaceholder?: boolean
}

export default interface IMarsDatePicker{
    fieldValues: MarsDatePickerType
}

export const MarsDatePickerDefualts: IMarsDatePicker = {
    fieldValues: {
        selector: "TEXTFIELD",
        name: "",
        formValues: {},
        setFormValues: () => {},
        label: "",
        datePickerViews: ['month', 'day', 'year'],
        minDate: null,
        maxDate: null,
        required: false,
        variant: "outlined",
        isPlaceholder: false
    }
}