import React from "react";
import {  TextField, Typography } from "@mui/material";
import TimeRangePicker from "../TimeRangePicker";
import MarsCheckbox from "./MarsCheckBox";
import MarsSlider from "./MarsSlider";
import MarsTextField from "./MarsTextField";
import MarsDatePicker from "./MarsDatePicker";

const CustomFields = (props: any) => {
    switch(props?.selector){
        case "CHECKBOXGROUP" :
            return(
                <MarsCheckbox fieldValues={props}/>
            )
        case "SLIDER" :
            return(
                <MarsSlider fieldValues={props}/>
            )
        case "TEXTFIELD" : 
            return(
                <MarsTextField fieldValues={props}/>
            )
        case "DATEPICKER" : 
            return(
                <MarsDatePicker fieldValues={props}/>
            )
        case "TIMERANGE" : 
            return(
                <div>
                    <Typography variant="h5" style={{flexGrow: 1}}>{props.label}</Typography>
                    <TimeRangePicker value={props.formValues} setValue={props.setFormValues} />
                </div>
            )
        default:
            <TextField />
    }
}

export default CustomFields;