import { IRoverLogs } from "../commons/interfaces/IRoverLogs";

export default function formatLog(data : IRoverLogs[]) {
    return data.map(log => ({
        sampleDay: log.sample_day,
        sampleTime: log.sample_time,
        roverId: log.rover_id,
        enteredService: log.entered_service,
        unitsWater: log.units_water,
        unitsMetal: log.units_metal,
        latitude: log.latitude,
        longitude: log.longitude,
    }));
}