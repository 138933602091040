import { IRoverDataStats } from "../services/Filter/IFilterSlice";

const startDate = new Date('1873-12-29');
const currentDate = new Date();
const differenceInMilliseconds = Math.abs(currentDate.getTime() - startDate.getTime());
const currentMarsDay = Math.round(differenceInMilliseconds / (24 * 60 * 60 * 1000));

const createFilterStats = () : IRoverDataStats => {
    return {
        "min_days": 54815,
        "max_days": currentMarsDay,
        "min_water": 0,
        "max_water": 199,
        "min_metal": 0,
        "max_metal": 199
    }
}

export default createFilterStats;