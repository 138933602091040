import React, { useEffect, useState } from "react"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import IMarsDatePicker, { MarsDatePickerDefualts } from "./IMarsDatePicker"
import dayjs from "dayjs"

const MarsDatePicker = (props: IMarsDatePicker) => {
    let fieldValues = props?.fieldValues

    const [datePickerValue, setDatePickerValue] = useState(dayjs().set('year', null))
    const [datePickerOpen, setDatePickerOpen] = useState(false)

    const [minDate, setMinDate] = useState(null)
    const [maxDate, setMaxDate] = useState(null)

    useEffect(() => {
        if(fieldValues.minDate){
            setDatesUsingDayjs(fieldValues.minDate, setMinDate)
        }
        if(fieldValues.maxDate){
            setDatesUsingDayjs(fieldValues.maxDate, setMaxDate)
        }
    }, [fieldValues.minDate, fieldValues.maxDate])

    useEffect(() => {
        if(fieldValues.formValues === undefined){
            setDatePickerValue(null)
        }else{
            if(fieldValues.formValues[fieldValues.name]){
                setDatePickerValue(dayjs().set('year', fieldValues.formValues[fieldValues.name]))
            }
        }
    }, [fieldValues.formValues, fieldValues.name])

    const setDatesUsingDayjs = (
        dateArray: number[],
        setFunc: (newValue: any) => void
    ) => {
            var [month, date, year] = dateArray
            if(month === 0){
                setFunc(dayjs()
                    .set('month', new Date().getMonth())
                    .set('date', new Date().getDate())
                    .set('year', new Date().getFullYear())
                )
            }else{
                setFunc(dayjs().set('month',month).set('date',date).set('year', year))
            }
    }

    const handleDatePickerChange = (
        event: any
    ) => {
        fieldValues.setFormValues({...fieldValues.formValues, [fieldValues.name] : event? event.$y : new Date().getFullYear()})
    }

    return(
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker 
                label={fieldValues.label} 
                views={fieldValues.datePickerViews}
                onChange={handleDatePickerChange}
                open={datePickerOpen}
                value={datePickerValue}
                minDate={minDate}
                maxDate={maxDate}
                onClose={() => setDatePickerOpen(false)}
                slotProps={{
                    textField: {
                        onClick: () => setDatePickerOpen(true)
                    }
                }}
            />
        </LocalizationProvider>
    )
}

MarsDatePicker.defaultfieldValues = MarsDatePickerDefualts

export default MarsDatePicker