import { createAsyncThunk } from "@reduxjs/toolkit"
import { Endpoints } from "../commons/constants/Endpoints"
import { RequestType } from "../commons/constants/RequestType"
import { roverIds } from "../commons/interfaces/RoverIds"
import listofLatestRoverIds from "../data/MockApiResponses/listofLatestRoverIds.json"
import roverDataStats from "../data/MockApiResponses/roverDataStatistics.json"
import { searchRoversByName, searchRoversByYear } from "../models/searchRovers"

const backendURL = process.env.REACT_APP_API_URL;
export const streamURL = process.env.REACT_APP_STREAM_URL;
export const filterURL = process.env.REACT_APP_FILTER_URL;

export const mockBackend = (url: string, body?: any) => {
    return new Promise((resolve, reject) => {
        switch(url){
            case Symbol.keyFor(Endpoints.LatestRovers):
                setTimeout(() => {
                    resolve(listofLatestRoverIds)
                }, 1500)
            break;
            case Symbol.keyFor(Endpoints.RoverDataStats):
                setTimeout(() => {
                    resolve(roverDataStats)
                }, 0)
            break;
            case Symbol.keyFor(Endpoints.RoversByName):
                searchRoversByName(body).then((response: roverIds[]) => {
                    resolve(response)
                }).catch((error) => {
                    reject(error)
                })
            break;
            case Symbol.keyFor(Endpoints.RoversByYear):
                searchRoversByYear(body).then((response: roverIds[]) => {
                    resolve(response)
                }).catch((error) => {
                    reject(error)
                })
            break;
            default:
                reject("invalid Url")
            break;
        }
    })
}

const callBackend = (url: string, requestParams: object, body?: any) => {
    return new Promise((resolve, reject) => {
        fetch(
            `${backendURL}${url}`, 
            body ? {...requestParams, ...{body: body}} : requestParams
        ).then((response) => {
            resolve(response.json())
        }).catch((error) => {
            reject(error)
        })
    })
}

const callFilterBackend = (url: string, requestParams: object, body?: any) => {
    return new Promise((resolve, reject) => {
        fetch(
            `${filterURL}${url}`, 
            body ? {...requestParams, ...{body: body}} : requestParams
        ).then((response) => {
            resolve(response.json())
        }).catch((error) => {
            reject(error)
        })
    })
}

export const HttpRequest = (
    name: string,
    type: symbol, 
    url : string
) => {
    if(RequestType.MOCK === type) {
        
        return createAsyncThunk(name, 
            (body?: any) => mockBackend(url,body).then((result) => {
                return result
            })
        )
    
    } else {

        var requestParams = {
            method: Symbol.keyFor(type),
            headers: {
                "Content-Type" : "application/json"
            }
        }

        return createAsyncThunk(name, 
            (body?: any) => callBackend(url, requestParams, body).then((result) => {
                return result 
            })
        )
    }
}

export const filterHttpRequest = async (
    url: string,
    type: symbol, 
  ) => {
    let method = Symbol.keyFor(type);
    let requestParams = {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
    };
  
    try {
      let response = await fetch(url, requestParams);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error("Error making HTTP request:", error);
      throw error;
    }
  };