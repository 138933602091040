export interface IMarsForm{
    searchForm: any,
    value: any,
    setValue: (newValue: any) => void
    extraProps?: any,
    fieldGap?: string,
    horizontal?: boolean
}

export const MarsFormDefaults = {
    searchForm: [{}],
    value: "",
    setValue: () => {},
    extraProps: [{}],
    fieldGap: '10px',
    horizontal: false
}