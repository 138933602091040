import { colors } from "../../constants/Colors";

export const muiTableCellLight = {
    styleOverrides: {
        head:{
            backgroundColor: Symbol.keyFor(colors.OrangeYellow),
            color: Symbol.keyFor(colors.White)
        }
    }
}

export const muiTableCellDark = {
    styleOverrides: {
        head:{
            backgroundColor: Symbol.keyFor(colors.Brown)
        }
    }
}