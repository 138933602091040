import "./index.css"
import React, { useEffect } from "react";
import { useAppDispatch } from "../../context/CustomHooks";
import { fetchRoverAssets, roverAssetsSelector } from "../../services/RoverAsset/RoverAssetSlice";
import { useSelector } from "react-redux";
import MarsTable from "../../components/MarsTable";
import {default as roverAssetColumns} from "../../data/assetTable.json"
import { Box } from "@mui/material";
import CustomDiv from "../../components/CustomDiv";

const Rovers = () => {

    const dispatch = useAppDispatch();

    const roverAssets = useSelector(roverAssetsSelector);

    useEffect(() => {
        dispatch(fetchRoverAssets(""))
    }, [dispatch])

    return(
        <CustomDiv horizontalPosition="center" verticalPosition="center">
            <Box className="table-wrapper">
                <MarsTable columns={roverAssetColumns} tableContent={roverAssets}/>
            </Box>
        </CustomDiv> 
    )
}

export default Rovers;