// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-wrapper{
    width: 100%; 
    position: fixed; 
    bottom: 0;
    left: 0;
    z-index: 100;
    display: flex; 
    flex-direction: column;
    justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/components/APIErrorDisplay/index.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,SAAS;IACT,OAAO;IACP,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,yBAAyB;AAC7B","sourcesContent":[".error-wrapper{\n    width: 100%; \n    position: fixed; \n    bottom: 0;\n    left: 0;\n    z-index: 100;\n    display: flex; \n    flex-direction: column;\n    justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
