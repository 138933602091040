export interface ILottieAnimation{
    animationEffect: object,
    loop?: boolean,
    height: number,
    width: number
}

export const LottieAnimationDefaults: ILottieAnimation = {
    animationEffect: {},
    loop: true,
    height: 0,
    width: 0
}