import "./index.css"
import React, { useState, useEffect, useCallback } from "react";
import { Box, Button, Chip, Grid, Link } from "@mui/material";
import { fetchFilterResults, fetchLatestRovers,latestRoversSelector, roverDataStatisticsSelector, transformRoverDataToString } from "../../services/Filter/FilterSlice";
import { setPage, clearFilterResults } from "../../services/Filter/FilterSlice";
import { useAppDispatch } from "../../context/CustomHooks";
import { useSelector } from "react-redux";
import { default as filterForm } from "../../data/filterForm.json"
import CustomDiv from "../../components/CustomDiv";
import { IFilterRequest } from "../../commons/interfaces/IFilterRequest";
import MarsForm from "../../components/MarsForm";
import { colors } from "../../commons/constants/Colors";
import { RoverScatterplot } from "../../components/GridGraph";
import LoggerUI from "../../components/LoggerUI";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";

const Home = () => {
    const dispatch = useAppDispatch();
    const flagValue = useFeatureFlag('react_filters_flag');

    const latestRovers = useSelector(latestRoversSelector)
    const roverDataStats = useSelector(roverDataStatisticsSelector)

    const [viewMode, setViewMode] = useState<'stream' | 'filtered'>('stream')
    const [filterRequest, setFilterRequest] = useState<IFilterRequest>(null)
    const [extraProps, setExtraProps] = useState([])

    const isDisplayFilter = flagValue ? 'block' : 'none';
    const logsSize = flagValue ? 9 : 12;

    useEffect(() => {
        dispatch(fetchLatestRovers(""))
    }, [dispatch])

    const initFilter = useCallback(() => {
        setFilterRequest((filterRequest) => 
            ({...filterRequest, ...{
                "min_days": roverDataStats?.min_days,
                "max_days": roverDataStats?.max_days,
                "min_water": roverDataStats?.min_water,
                "max_water": roverDataStats?.max_water,
                "min_metal": roverDataStats?.min_metal,
                "max_metal": roverDataStats?.max_metal,
                "rovers": [],
                "start_time": null,
                "end_time": null }
            })
        )

        setExtraProps([])

        filterForm?.map(fields => {
            setExtraProps((extraProps) => 
                ([...extraProps, {
                    minValue: roverDataStats? roverDataStats[`min_${fields?.name}`]: "",
                    maxValue: roverDataStats? roverDataStats[`max_${fields?.name}`]: "",
                    checkboxValues: latestRovers
                }])
            )
            return true
        })
    }, [roverDataStats, latestRovers])

    useEffect(() => {
        initFilter()
    }, [initFilter])

    const handleChipDelete = (rover: string) => {
        var index = filterRequest["rovers"].findIndex((val: any) => rover === val)
        setFilterRequest({
            ...filterRequest,
            "rovers": [
                ...filterRequest["rovers"].slice(0, index),
                ...filterRequest["rovers"].slice(index+1)
            ]
        })
    }

    const handleFilterAction = () => {
        dispatch(setPage(0))
        dispatch(clearFilterResults(undefined))
        const strFilterRequest = transformRoverDataToString(filterRequest)
        dispatch(fetchFilterResults(strFilterRequest))
        setViewMode('filtered');
    }

    const clearFiltersAndResetView = () => {
        initFilter();
        dispatch(setPage(0))
        dispatch(clearFilterResults(undefined))
        setViewMode('stream');
      };

    return(
        <Grid container spacing={2} style={{marginTop: '40px'}}>
            <Grid item xs={logsSize} sm={logsSize} md={logsSize} lg={logsSize}>
                {filterRequest && 
                    filterRequest["rovers"].length > 0 &&
                <Box className="chips-wrapper">
                    <CustomDiv>
                        {filterRequest["rovers"].map((ele: string, index: number) => (
                            <Chip 
                                key={index}
                                label={ele}
                                onDelete={() => handleChipDelete(ele)}
                                variant="outlined"
                            />
                        ))
                        }
                    </CustomDiv>
                </Box>}

                <LoggerUI viewMode={viewMode} filterRequest={filterRequest}/>
                <RoverScatterplot viewMode={viewMode}/>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} sx={{ display: isDisplayFilter}}>
                <Box className="filter-wrapper">
                    <CustomDiv horizontalPosition="right">
                        <Link style={{color: Symbol.keyFor(colors.Red)}} onClick={clearFiltersAndResetView}>CLEAR ALL</Link>
                    </CustomDiv>

                    {filterRequest && <MarsForm 
                        searchForm={filterForm} 
                        extraProps={extraProps} 
                        value={filterRequest} 
                        setValue={setFilterRequest}
                    />}

                    <CustomDiv horizontalPosition="center">
                        <Button variant="contained" onClick={handleFilterAction}>Apply Filters</Button>
                    </CustomDiv>
                </Box>
            </Grid>
        </Grid>
    )
}

export default Home;