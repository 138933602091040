import React, { useEffect, useState } from "react";
import { Alert, AlertColor, AlertTitle, Box, Button, Collapse, IconButton } from "@mui/material";
import { ErrorAlertDefaults, IErrorAlert } from "./IErrorAlert";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { errorSelector, setError } from "../../services/ThunkMiddleware/ThunkMiddlewareSlice";
import { useAppDispatch } from "../../context/CustomHooks";

/**
 * 
 * @param props
 * @returns react component to display custom error/warning/info and success messages to users
 */
const ErrorAlert = (props: IErrorAlert) => {
    const dispatch = useAppDispatch()

    const [collapsableList, setCollapsableList] = useState([])
    const errors = useSelector(errorSelector);

    useEffect(() => {
        if(props.collapsable){
            setCollapsableList((prevState) => [...prevState, props.id])
        }
    }, [props.collapsable, props.id])

    const closeAlert = (id: string) => {
        var index = collapsableList.findIndex(x => x === id)

        setCollapsableList([
            ...collapsableList.slice(0, index),
            ...collapsableList.slice(index+1)
        ])

        if(Object.keys(errors).includes(props.id)){
            var temp = {...errors}
            delete temp[props.id]

            dispatch(setError(temp))
        }
    }

    return(
        <Collapse in={collapsableList.includes(props.id)}>
            <Alert
                action={
                    props.collapsable &&
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => closeAlert(props.id)}
                    >
                        <Close />
                    </IconButton>
                }
                severity={props.severity as AlertColor}
                variant={props.variant as "standard" | "filled" | "outlined"}
                style={props.style}
            >
                <AlertTitle>{props.title}</AlertTitle>
                {props.message}
                {props.confirmationBtn && 
                    <Box style={{marginTop: "2vw"}}>
                        <Button 
                            variant="contained"     
                            style={{background: props.confirmationBtnColor}}
                        >
                                {props.confirmaionBtnText}
                        </Button>
                    </Box>
                }
            </Alert>
        </Collapse>
    )
}

ErrorAlert.defaultProps = ErrorAlertDefaults

export default ErrorAlert;