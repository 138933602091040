import { colors } from "../../constants/Colors"

export const palette = {
    primary: {
        main: Symbol.keyFor(colors.OrangeYellow)
    },
    secondary: {
        main: Symbol.keyFor(colors.Grey)
    },
    warning: {
        main: Symbol.keyFor(colors.Red)
    },
    success: {
        main: Symbol.keyFor(colors.Green)
    },
    info: {
        main: Symbol.keyFor(colors.InfoBlack)
    }
}

export const darkPalette = {
    primary: {
        main: Symbol.keyFor(colors.Brown)
    },
    secondary: {
        main: Symbol.keyFor(colors.DarkGrey)
    },
    warning: {
        main: Symbol.keyFor(colors.Red)
    },
    success: {
        main: Symbol.keyFor(colors.Green)
    },
    info: {
        main: Symbol.keyFor(colors.LightGrey)
    },
    background: {
        default: Symbol.keyFor(colors.BgBlack)
    },
    text: {
        primary: Symbol.keyFor(colors.White)
    }
}