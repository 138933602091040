import { colors } from "../../constants/Colors";

export const muiTableRowLight = {
    styleOverrides: {
        root:{
            ":nth-of-type(even) > td":{
                background: Symbol.keyFor(colors.LightGrey)
            }
        }
    }
}

export const muiTableRowDark = {
    styleOverrides: {
        root:{
            ":nth-of-type(even) > td":{
                background: Symbol.keyFor(colors.DarkGrey)
            }
        }
    }
}