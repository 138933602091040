// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    color: white;
    font-size: 12px;
    padding: 4px;
    white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/components/GridGraph/Tooltip/index.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,oCAAoC;IACpC,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":["\n.tooltip {\n    position: absolute;\n    background-color: rgba(0, 0, 0, 0.8);\n    border-radius: 4px;\n    color: white;\n    font-size: 12px;\n    padding: 4px;\n    white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
