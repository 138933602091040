import { AnyAction, AsyncThunk, SerializedError, createSlice } from "@reduxjs/toolkit";

type GenericAsyncThunk = AsyncThunk<unknown,unknown,any>;

type PendingAction = ReturnType<GenericAsyncThunk['pending']>
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>

/* add error and its type to RejectedAction */
type Modify<T, R> = Omit<T, keyof R> & R;
type ModifiedRejectedAction = Modify<RejectedAction,{
  error:SerializedError
}>

/* matcher functions */
function isPendingAction(action: AnyAction): action is PendingAction {
  return action.type.endsWith('/pending')
}
function isFulfilledAction(action: AnyAction): action is FulfilledAction {
  return action.type.endsWith('/fulfilled')
}
function isRejectedAction(action: AnyAction): action is ModifiedRejectedAction {
  return action.type.endsWith('/rejected')
}

/** Global slice to handle pending, fullfiled and errored states of the thunk */
const thunkMiddlewareSlice = createSlice({
    name: "thunkMiddleware",
    initialState: {
        error: {} as any,
        loading: {} as any
    },
    reducers: {
      setError: (state, action) => {
        state.error = action.payload
      }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isPendingAction,(state,action)=>{
                const serviceKey= action.type.split("/")[1]
                state.loading[serviceKey] = true;
            })
            .addMatcher(isFulfilledAction,(state,action)=>{
                const serviceKey= action.type.split("/")[1]
                state.loading[serviceKey] = false;
                delete state.error[serviceKey];
            })
            .addMatcher(isRejectedAction,(state,action)=>{
                const serviceKey= action.type.split("/")[1]
                state.loading[serviceKey] = false;
                state.error[serviceKey] = action.error.message || "Internal Server Error";
            })
    }
})

export const { setError } = thunkMiddlewareSlice.actions;

export const errorSelector = (state: any) => state.thunkMiddleware.error;
export const loadingSelector = (state: any) => state.thunkMiddleware.loading;

export default thunkMiddlewareSlice.reducer