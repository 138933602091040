import React, { useEffect, useState } from "react"
import { Slider, Typography } from "@mui/material"
import IMarsSlider from "./IMarsSlider"

const MarsSlider = (props: IMarsSlider) => {
    let fieldValues = props?.fieldValues
    const [sliderValues, setSliderValues] = useState([0, 0])

    const marks = [
        {
            value: fieldValues.minValue,
            label: fieldValues.minValue
        },
        {
            value: fieldValues.maxValue,
            label: fieldValues.maxValue
        }
    ];

    useEffect(() => {
        if(fieldValues.formValues === undefined){
            setSliderValues([0, 0])
        }else{
            setSliderValues([
                fieldValues.formValues[`min_${fieldValues.name}`], 
                fieldValues.formValues[`max_${fieldValues.name}`]
            ])
        }
    }, [fieldValues.formValues, fieldValues.name])

    const handleSliderChange = (
        event: any,
    ) => {
        if (!Array.isArray(event.target.value)) {
            return;
        }
        
        (fieldValues.setFormValues({...fieldValues.formValues, 
            ...{[`min_${event.target.name}`] : 
                    Math.min(event.target.value[0], fieldValues.formValues? sliderValues[1] - 0 : 0), 
                [`max_${event.target.name}`] :
                    Math.max(event.target.value[1], fieldValues.formValues? sliderValues[0] + 0 : 0)}
        }));
    }

    return(
        <div>
            <Typography variant="h5" style={{flexGrow: 1}}>{fieldValues.label}</Typography>
            <Slider
                value={sliderValues}
                min={fieldValues.minValue}
                step={1}
                max={fieldValues.maxValue}
                onChange={handleSliderChange}
                valueLabelDisplay="auto"
                disableSwap= {true}
                marks = {marks}
                name={fieldValues.name}
            />
        </div>
    )
   
}

export default MarsSlider