import { muiButton } from "./items/MuiButton";
import { muiDrawer } from "./items/MuiDrawer";
import { muiFilledInput } from "./items/MuiFilledInput";
import { muiInputLabel } from "./items/MuiInputLabel";
import { muiLink } from "./items/MuiLink";
import { muiListItem } from "./items/MuiListItem";
import { muiOutlinedInput } from "./items/MuiOutlinedInput";
import { palette, darkPalette } from "./designs/Palette";
import { typography } from "./designs/Typography";
import { PaletteMode } from "@mui/material";
import { muiCheckbox } from "./items/MuiCheckbox"
import { muiTableCellDark, muiTableCellLight } from "./items/MuiTableCell";
import { muiTableRowDark, muiTableRowLight } from "./items/MuiTableRow";

/** Defines global styles and colors for the application */
export const getTheme = (mode: PaletteMode) => ({
    palette: 
        (mode === 'light'
            ?
                palette
            
            : 
                darkPalette
            )
        ,
    typography: typography,
    components: {
        MuiButton: muiButton,
        MuiDrawer: muiDrawer,
        MuiLink: muiLink,
        MuiFilledInput: muiFilledInput,
        MuiListItem: muiListItem,
        MuiOutlinedInput: muiOutlinedInput,
        MuiInputLabel: muiInputLabel,
        MuiCheckbox: muiCheckbox,
        MuiTableCell: mode === "light" ? 
                muiTableCellLight : 
                muiTableCellDark,
        MuiTableRow: mode === "light" ?  
                muiTableRowLight :
                muiTableRowDark
    }
});


export default getTheme;