import React from "react";
import "./index.css"
import { IRoverLogs } from "../../../commons/interfaces/IRoverLogs";

  type TooltipProps = {
    interactionData: IRoverLogs | null;
    xScale: d3.ScaleLinear<number, number>;
    yScale: d3.ScaleLinear<number, number>;
  };

  export const Tooltip = ({ interactionData, xScale, yScale  }: TooltipProps) => {
    if (!interactionData) {
      return null;
    }


    const left = xScale(interactionData.longitude); 
    const top = yScale(interactionData.latitude);   

    return (
      interactionData && <div
        className= "tooltip"
        style={{
          left: `${left}px`,
          top: `${top}px`,
        }}
      >
        cords: ({interactionData.longitude},{interactionData.latitude}) <br/>
        rover: {interactionData.rover_id} <br/>
        metal value: {interactionData.units_metal} <br/>
        water value: {interactionData.units_water} <br/>
      </div>
    );
  };