// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar {
    display: flex; 
    width: 100%; 
    justify-content: space-between; 
    align-items: center;
}

.link {
    color: white !important;
    margin: 0 0.2vw;
    cursor: pointer;
    padding: 0.6vw 1vw;
    border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/Navigation/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;IACvB,eAAe;IACf,eAAe;IACf,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".toolbar {\n    display: flex; \n    width: 100%; \n    justify-content: space-between; \n    align-items: center;\n}\n\n.link {\n    color: white !important;\n    margin: 0 0.2vw;\n    cursor: pointer;\n    padding: 0.6vw 1vw;\n    border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
