import * as React from 'react';
import './App.css';
import Navigation from './components/Navigation';
import AppRouter from './commons/AppRouter';
import { CssBaseline, PaletteMode, createTheme } from '@mui/material';
import { FFContextProvider } from '@harnessio/ff-react-client-sdk'
import { ThemeProvider } from '@emotion/react';
import getTheme from './commons/styles/Theme';
import { useSelector } from 'react-redux';
import { modeSelector } from './services/ModeSlice';
import { errorSelector, loadingSelector } from './services/ThunkMiddleware/ThunkMiddlewareSlice';
import Loader from './components/Loader';
import APIErrorDisplay from './components/APIErrorDisplay';

const App = () => {
    const mode = useSelector(modeSelector);
    const loading = useSelector(loadingSelector)
    const errors = useSelector(errorSelector)
    
    const theme = React.useMemo(() => createTheme(getTheme(mode as PaletteMode)), [mode]);

    const HarnessSDK = process.env.REACT_APP_FF_KEY;

    return(
        <FFContextProvider 
            apiKey={HarnessSDK}
            target={{
                identifier: 'HT_1',
                name: 'Harness_Target_1'
                  }}
                >
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Navigation />
                <AppRouter />
                {/* {loading && Object.values(loading).includes(true) && <Loader data-testid="loader"/>} */}
                {errors && Object.values(errors).length > 0 && <APIErrorDisplay />}
            </ThemeProvider>
        </FFContextProvider>
    )
}

export default App;
