export const colors = Object.freeze({
    OrangeYellow: Symbol.for("rgb(255, 165, 0)"),
    Grey: Symbol.for("rgb(243, 243, 243)"),
    Red: Symbol.for("rgb(255, 0, 0)"),
    Green: Symbol.for("rgb(120, 252, 155)"),
    InfoBlack: Symbol.for("rgb(37, 37, 37)"),
    White: Symbol.for("rgb(255, 255, 255)"),
    BgBlack: Symbol.for("rgb(37, 37, 37)"),
    Brown: Symbol.for("rgb(66, 43, 1)"),
    DarkGrey: Symbol.for("rgb(150, 150, 150)"),
    LightGrey: Symbol.for("rgb(240, 240, 240)")
})


const RoverColors = {
    busYellow: "#e0ac2b",
    salmon: "#e85252",
    lightBlue: "#6689c6",
    lightPurple: "#9a6fb0",
    darkPink: "#a53253",
    lightGreen: "#2be037",
    darkPurple: "#640f6e",
    pink: "#c7147f",
    teal: "#2bcff0",
    darkGreen: "#3b7540",
    darkBlue: "#40275e",
    brightGreen:  "#08fca3",
}


export const RoverColorScale = [
    RoverColors.busYellow, RoverColors.salmon, RoverColors.lightBlue,
    RoverColors.lightPurple, RoverColors.darkPink, RoverColors.lightGreen,
    RoverColors.darkPurple, RoverColors.pink, RoverColors.teal,
    RoverColors.darkGreen, RoverColors.darkBlue, RoverColors.brightGreen
    
]