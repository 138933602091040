import { createSlice } from "@reduxjs/toolkit";
import { streamURL } from "../../context/ApiRequest";
import formatLog from "../../helpers/formatLog";
import { ILogEntry } from "../../commons/interfaces/ILogEntry";

export const initialState = {
   logs: null as ILogEntry[]
};

const roverLogsSlice = createSlice({
    name: "roverLogs",
    initialState,
    reducers: {
        setLogs: (state, action) => {
            state.logs = 
                state.logs ? 
                [...state.logs, ...action.payload] : 
                action.payload
        }
    },
    
})

export const fetchLogStream = () => {
    return(dispatch: any) => {
        try{
            const eventSource = new EventSource(`${streamURL}/logs`)

            eventSource.addEventListener('message', (event) => {
                const formattedLogs = formatLog(JSON.parse(event.data))
                dispatch(setLogs(formattedLogs))
            })
    
            eventSource.addEventListener('error', (event) => {
                eventSource.close()
            })
        }catch(error){
            console.log(error)
        }
    }
}

export const {setLogs} = roverLogsSlice.actions;
export const logsSelector = (state: any) => state.roverLogs.logs;
export default roverLogsSlice.reducer;
