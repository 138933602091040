export const muiFilledInput = {
    styleOverrides:{
        input:{
            padding: '8px 10px',
        },
        root: {
            borderRadius: '50px',
            '&&&:before': {
                borderBottom: 'none'
            },
            '&&:after': {
                borderBottom: 'none'
            }
        }
    }
}