export const typography = {
    allVariants: {
        fontFamily: "'Lato', sans-serif",
    },
    h1: {
        fontSize: '3.5rem'
    },
    h2: {
        fontSize: '2.5rem'    
    },
    h3: {
        fontSize: '2rem'
    },
    h4: {
        fontSize: '1.5rem'
    },
    h5 : {
        fontSize: '1.25rem'
    },
    h6: {
        fontSize: '1rem'
    },
    body1: {
        fontSize: '0.9rem'
    },
    body2: {
        fontSize: '0.85rem'
    }
}

