import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HttpRequest, filterHttpRequest } from "../../context/ApiRequest";
import { IRoverDataStats, FilterSliceValues } from "./IFilterSlice";
import { RequestType } from "../../commons/constants/RequestType";
import { Endpoints } from "../../commons/constants/Endpoints";
import { roverIds } from "../../commons/interfaces/RoverIds";
import { IFilterRequest } from "../../commons/interfaces/IFilterRequest";
import { filterURL } from "../../context/ApiRequest";
import createFilterStats from "../../helpers/createFilterStats";

export const initialState : FilterSliceValues = {
    latestRovers: [],
    roverDataStatistics: createFilterStats(),
    filterResults: [],
    pagination: {
        page: 0,
        size: 100,
      },
      hasMore: true,
}

export const transformRoverDataToString = (data: IFilterRequest): string => {
  const parts: string[] = [];

  Object.entries(data).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
          switch (key) {
              case 'rovers':
                  if (Array.isArray(value) && value.length) {
                      parts.push(`roverId=${value.join(',')}`);
                  }
                  break;
              case 'start_time':
                  const startTime = (value as Date).toLocaleTimeString('en-GB', { hour12: false });
                  parts.push(`sampleTimeStart=${startTime}`);
                  break;
              case 'end_time':
                  const endTime = (value as Date).toLocaleTimeString('en-GB', { hour12: false });
                  parts.push(`sampleTimeEnd=${endTime}`);
                  break;
              case 'min_days':
                    parts.push(`sampleDayStart=${value}`);
                    break;
              case 'max_days':
                    parts.push(`sampleDayEnd=${value}`);
                    break;
              default:
                  const prefix = key.startsWith('min_') ? 'Start' : key.startsWith('max_') ? 'End' : '';
                  const name = key.replace('min_', '').replace('max_', '');
                  const parameterName = `units${name.charAt(0).toUpperCase() + name.slice(1)}${prefix}`;
                  parts.push(`${parameterName}=${value}`);
                  break;
          }
      }
  });

  return parts.join('&');
};

const filterSlice = createSlice({
    name: "filter",
    initialState,
    reducers : {
        setPage(state, action) {
            state.pagination.page = action.payload;
          },
        setSize(state, action) {
            state.pagination.size = action.payload;
          },
        setHasMore(state, action) {
            state.hasMore = action.payload;
          },
        clearFilterResults(state, action) {
            state.filterResults = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLatestRovers.fulfilled, (state, action) => {
                state.latestRovers = action.payload as roverIds[]
            })
            .addCase(fetchFilterResults.fulfilled, (state, action) => {
                state.filterResults = state.filterResults.length > 0
                    ? [...state.filterResults, ...action.payload]
                    : action.payload;
            })
    }
})

export const fetchLatestRovers = HttpRequest(`filter${Symbol.keyFor(Endpoints.LatestRovers)}`, RequestType.MOCK, Symbol.keyFor(Endpoints.LatestRovers))


export const fetchFilterResults = createAsyncThunk(
  'filter/fetchFilterResults',
  async (conditions : string, { getState, rejectWithValue }) => {
    try {
      const state : any = getState();
      const { page, size } = state.filter.pagination;
      const queryParams = `${conditions}&page=${page}&size=${size}`
      const response = await filterHttpRequest(`${filterURL}/api/rover/search?${queryParams}`, RequestType.GET);
      return response; 
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const latestRoversSelector = (state: any) => state.filter.latestRovers
export const roverDataStatisticsSelector = (state: any) => state.filter.roverDataStatistics
export const filterResultsSelector = (state: any) => state.filter.filterResults
export const paginationSelector = (state: any) => state.filter.pagination
export const hasMoreSelector = (state: any) => state.filter.hasMore

export const { setPage, clearFilterResults } = filterSlice.actions;

export default filterSlice.reducer;