import React, { useEffect, useState } from "react"
import { LocalizationProvider, TimePicker, renderTimeViewClock } from "@mui/x-date-pickers"
import ITimeRangePicker, { defaultTimeRangePickerProps } from "./ITimeRangePicker"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import CustomDiv from "../CustomDiv"


const TimeRangePicker = (props: ITimeRangePicker) => {
    const [openStartTimeClock, setOpenStartTimeClock] = useState(false)
    const [openEndTimeClock, setOpenEndTimeClock] = useState(false)
    const [timeRange, setTimeRange] = useState([null, null])
    const [minTime, setMinTime] = useState(null)


    useEffect(()=> {
        setTimeRange([props.value[props.minKey] || null, props.value[props.maxKey] || null])
    }, [props.value, props.minKey, props.maxKey])

    const handleStartTimeChange = (
        event: any
    ) => {
        if(event != null){
            setMinTime(dayjs().set('hour', event.$H).set('minute', event.$m))
            props.setValue({...props.value, [props.minKey]: event.$d}) //in UTC string format
        }
    }
    
    const handleEndTimeChange = (
        event: any
    ) => {
        props.setValue({...props.value, [props.maxKey]: event.$d}) //in UTC string format
    }

    const handleStartTimeClockClose = () => {
        setOpenStartTimeClock(false)
        setOpenEndTimeClock(true)
    }

    return(
        
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CustomDiv style={{margin: '0.6vw 0'}}>
                <TimePicker
                    label={props.minLabel}
                    viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock
                    }}
                    desktopModeMediaQuery='@media(min-width: 900px)'
                    ampmInClock={true}
                    open={openStartTimeClock}
                    onClose={handleStartTimeClockClose}
                    slotProps={{
                        textField: {
                            onClick: () => setOpenStartTimeClock(true)
                        }
                    }}
                    onChange={(event) => handleStartTimeChange(event)}
                    value={timeRange[0]}
                />
                <TimePicker
                    label={props.maxLabel}
                    viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock
                    }}
                    ampmInClock={true}
                    open={openEndTimeClock}
                    onClose={() => setOpenEndTimeClock(false)}
                    desktopModeMediaQuery='@media(min-width: 900px)'
                    slotProps={{
                        textField: {
                            onClick: () => setOpenEndTimeClock(true)
                        }
                    }}
                    minTime={minTime}
                    onChange={(event) => handleEndTimeChange(event)}
                    value={timeRange[1]}
                />
            </CustomDiv>
        </LocalizationProvider>
    )
}

TimeRangePicker.defaultProps = defaultTimeRangePickerProps;

export default TimeRangePicker