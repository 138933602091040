import React from "react"
import { Route, Routes } from "react-router-dom";
import Rovers from "../../pages/Rovers";
import Home from "../../pages/Home";

const AppRouter = () => {
    return(
        <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/rovers" element={<Rovers />}></Route>
        </Routes>
    )
}

export default AppRouter;