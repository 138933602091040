import "./index.css"
import * as React from "react";
import { Home, ListAlt, Menu, Rocket } from "@mui/icons-material";
import { AppBar, Link, Toolbar, Typography, Box, IconButton, Drawer, List, ListItem, ListItemButton, ListItemAvatar } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { default as navigationItmes } from "../../data/navigationItems.json";
import ToggleTheme from "../ToggleTheme";
import { colors } from "../../commons/constants/Colors";

const Navigation = () => {
    const navigate = useNavigate();

    const [open, setOpen] = useState(false)
    const [pathName, setPathName] = useState('/')

    const handleNavigation = (link: any) => {
        navigate(link)
        setPathName(window.location.pathname)
        if(open){
            setOpen(false)
        }
    }

    return(
        <AppBar position="fixed">
                <Toolbar>
                    <Box className="toolbar">
                        <IconButton sx={{display: {xs: 'block', sm: 'block', md: 'none', lg: 'none'}}} onClick={() => setOpen(true)}><Menu style={{color: Symbol.keyFor(colors.White)}}/></IconButton>
                        <Typography variant="h5" style={{color: 'white', flexGrow: 1}}>Mars Rovers</Typography>
                        <ToggleTheme />
                        <Box sx={{display: {xs: 'none', sm: 'none', md: 'block', lg: 'block'}}}>
                            {navigationItmes?.map((item, index) => (
                                <Link key={index} 
                                    style={{background: item?.link === pathName ? 'rgba(250,250,0,0.2)' : 'transparent'}}
                                    className="link"
                                    onClick={() => handleNavigation(item?.link)}
                                >
                                    {item?.name}
                                </Link>
                            ))}
                        </Box>
                    </Box>
                </Toolbar>
                <Drawer role="drawer" open={open} onClose={() => setOpen(false)} sx={{display: {xs: 'block', sm: 'block', md: 'none', lg: 'none'}}}>
                    <List style={{padding: '3vw 0'}}>
                        {navigationItmes?.map((item, index) => (
                            <ListItem key={index} style={{padding: 0}}>
                                <ListItemButton onClick={() => handleNavigation(item?.link)}>
                                    <ListItemAvatar>
                                        {(() => { switch(item?.selector) {
                                            case 'HOME': 
                                                return (<Home />)
                                            case 'ROVERS': 
                                                return (<Rocket />)
                                            default:
                                                return (<ListAlt />)
                                        }})()}
                                    </ListItemAvatar>
                                    <Typography variant="h6">{item?.name}</Typography>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
        </AppBar>
    )
}

export default Navigation;
