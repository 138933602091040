import React from "react";
import { ILottieAnimation, LottieAnimationDefaults } from "./ILottieAnimation";
import Lottie from 'react-lottie';

const LottieAnimation = (props: ILottieAnimation) => {
    const defaultOptions = {
        loop: props.loop,
        autoplay: true, 
        animationData: props.animationEffect,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return(
        <Lottie options={defaultOptions} height={props.height} width={props.width}/>
    )
}

LottieAnimation.defaultProps = LottieAnimationDefaults;

export default LottieAnimation;