const { createSlice } = require("@reduxjs/toolkit");

const themeSlice = createSlice({
    name: "theme",
    initialState: {
        mode : 'light'
    },
    reducers: {
        setMode: (state: any, action: any) => {
            state.mode = action.payload
        }
    }
})



export const { setMode } = themeSlice.actions;

export const modeSelector = (state: any) => state.theme.mode;

export default themeSlice.reducer;