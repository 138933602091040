import React from 'react';
import { Typography } from '@mui/material';
import CustomDiv from '../CustomDiv';
import MarsIcon from '../MarsIcon';
import { PlayArrow } from '@mui/icons-material';
import { ILogEntry } from '../../commons/interfaces/ILogEntry';

interface LogProps {
    log: ILogEntry
}

export default function Log ({ log } : LogProps) {
    return (
        <CustomDiv style={{ whiteSpace: 'nowrap' }}>
            <MarsIcon icon={<PlayArrow />} />
            <Typography variant="body2" component="div" style={{ wordSpacing: "8px" }}>
                Day {log.sampleDay} {log.sampleTime} {"--->"} rover: {log.roverId} service: {log.enteredService} water: {log.unitsWater} metal: {log.unitsMetal} lat: {log.latitude} lng: {log.longitude}
            </Typography>
        </CustomDiv>
    )
}