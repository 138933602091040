import * as d3 from "d3";
import { AxisY } from "./Axes/Yaxis";
import { AxisX } from "./Axes/Xaxis";
import { useState } from "react";
import { Tooltip } from "./Tooltip/index";
import React from "react";
import { RoverColorScale } from "../../commons/constants/Colors";
import { IRoverLogs } from "../../commons/interfaces/IRoverLogs";
import { ILogEntry } from "../../commons/interfaces/ILogEntry";

const MARGIN = { top: 80, right: 25, bottom: 75, left: 75 };
let MarsMap = require('../../commons/images/MarsMap.jpeg')

type ScatterplotProps = {
  width: number;
  height: number;
  data: ILogEntry[];
};

export const ScatterplotFrame = ({ width, height, data }: ScatterplotProps) => {
  const boundsWidth = width - MARGIN.right - MARGIN.left;
  const boundsHeight = height - MARGIN.top - MARGIN.bottom;

  const [hovered, setHovered] = useState<IRoverLogs | null>(null);

  // Scales
  const yScale = d3.scaleLinear().domain([-90, 90]).range([boundsHeight, 0]);
  const xScale = d3.scaleLinear().domain([150, -150]).range([0, boundsWidth]);
  // Color Scales
  const allGroups = data && Array.isArray(data) ? data.map((d) => String(d.roverId)) : [];
  const colorScale = d3
    .scaleOrdinal<string>()
    .domain(allGroups)
    .range(RoverColorScale);

  const allShapes = data && Array.isArray(data) ? data.map((d, i) => {
    return (
      <circle
        key={i}
        r={8}
        cx={xScale(d.longitude)}
        cy={yScale(d.latitude)}
        stroke={colorScale(d.roverId )}
        fill={colorScale(d.roverId)}
        fillOpacity={0.7}
        onMouseEnter={() =>
          setHovered({
            longitude: (d.longitude),
            latitude: (d.latitude),
            rover_id: d.roverId,
            entered_service: d.enteredService,
            sample_day: d.sampleDay,
            units_metal: d.unitsMetal,
            units_water: d.unitsWater,
            sample_time: d.sampleTime
          })
        }
        onMouseLeave={() => setHovered(null)}
      />
    );
  }) : [];

  return (
    <div style={{ 
        top: -40,
        left: 0,
        position: "relative",
        width: `${boundsWidth + MARGIN.left + MARGIN.right}px`,  
        height: `${boundsHeight + MARGIN.top + MARGIN.bottom}px`,  
        }}>
    <div
        style={{ 
            position: "absolute",
            width: `100%`,
            height: `100.5%`,
            backgroundImage: `url(${MarsMap})`, 
            backgroundSize: `${boundsWidth}px ${boundsHeight}px`, 
            backgroundPosition: "75% center", 
            backgroundRepeat: "no-repeat", 
            zIndex: -2,
        }} 
     />
      <svg width={width} height={height}>
        <g
          width={boundsWidth}
          height={boundsHeight}
          transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
        >
          {/* Y axis */}
          <AxisY yScale={yScale} pixelsPerTick={30} width={boundsWidth} />

          {/* X axis */}
          <g transform={`translate(0, ${boundsHeight})`}>
            <AxisX xScale={xScale} pixelsPerTick={30} height= {boundsHeight}/>
          </g>

          {/* Circles */}
          {allShapes}
        </g>
      </svg>

      {/* Tooltip */}
      <div
        style={{
          width: boundsWidth,
          height: boundsHeight,
          position: "absolute",
          top: 0,
          left: 0,
          pointerEvents: "none",
          marginLeft: MARGIN.left,
          marginTop: MARGIN.top,
        }}
      >
        <Tooltip interactionData={hovered} xScale={xScale} yScale={yScale}/>
      </div>
    </div>
  );
};