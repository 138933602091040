import React from "react"
import { Box } from "@mui/material"
import ICustomDiv, { defaultCustomDivProps } from "./ICustomDiv"

const CustomDiv = (props: ICustomDiv) => {
    return(
        <Box style={{...{display: 'flex',
                flexDirection: props.direction,
                justifyContent: props.horizontalPosition, 
                alignItems: props.verticalPosition,
                gap: props.gap
            }, ...props.style}}
        >  
                {props.children}
        </Box>
    )
}

CustomDiv.defaultProps = defaultCustomDivProps

export default CustomDiv