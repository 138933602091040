import { TextFieldVariants } from "@mui/material"
import { ReactNode } from "react"

type MarsTextFieldType = {
    selector: string,
    name: string,
    formValues: any,
    setFormValues: (newValue: any) => void,
    label?: string,
    validation?: string,
    required?: boolean,
    variant?: TextFieldVariants,
    iconPosition?: "end" | "start",
    icon?: ReactNode,
    iconClickAction?: () => void,
    isPlaceholder?: boolean,
    searchable?: boolean
}

export default interface IMarsTextField {
    fieldValues: MarsTextFieldType
}

export const MarsTextFieldDefaults: IMarsTextField = {
    fieldValues: {
        selector: "TEXTFIELD",
        name: "",
        formValues: {},
        setFormValues: () => {},
        label: "",
        validation: "",
        required: false,
        variant: "outlined",
        iconPosition: "start",
        icon: "",
        iconClickAction: () => {},
        isPlaceholder: false,
        searchable: false
    }
}