import { ReactNode } from "react"

export default interface ICustomDiv{
    children: ReactNode
    horizontalPosition: string,
    verticalPosition?: string,
    direction?: string,
    gap?: string,
    style?: any
}   

export const defaultCustomDivProps: ICustomDiv = {
    children: "",
    horizontalPosition: "left",
    verticalPosition: "center",
    direction: "row",
    gap: "5px",
    style: ""
}