// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper{
    padding: 2vw;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/MarsForm/index.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,iDAAiD;AACrD","sourcesContent":[".wrapper{\n    padding: 2vw;\n    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
