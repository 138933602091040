export interface IErrorAlert{
    variant: string,
    severity: string,
    title: string,
    message: string,
    style?:any,
    confirmationBtn?: boolean,
    confirmaionBtnText?: string,
    confirmationBtnColor?: string,
    collapsable?: boolean,
    id?: string
}

export const ErrorAlertDefaults : IErrorAlert = {
    variant: "standard",
    severity: "info",
    title: "Title",
    message: "Alert message",
    style: {},
    confirmationBtn: false,
    confirmaionBtnText: "",
    confirmationBtnColor: "red",
    collapsable: false,
    id: ""
}