export default interface ITimeRangePicker{
    value: any,
    setValue:  (newValue: any) => void,
    minKey?: string,
    maxKey?: string,
    minLabel?: string,
    maxLabel?: string
}

export const defaultTimeRangePickerProps: ITimeRangePicker = {
    value: {},
    setValue: () => {},
    minKey: "start_time",
    maxKey: "end_time",
    minLabel: "Start Time",
    maxLabel: "End Time"
} 